footer{
    background-color: #E6E6E6 !important;
    
}
footer p {
    font-size: large;
}
.icon{
margin: 5px !important;
}
/* .mail{
    font-size:1.2rem;
} */